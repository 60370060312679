import React from 'react'
import { useHelmet, HelmetProps } from './useHelmet'
import { HelmetProvider, Helmet as ReactHelmet } from 'react-helmet-async'

const Helmet: React.FC<HelmetProps> = (props: HelmetProps) => {
  const { children, name, description, username } = useHelmet(props)

  return (
    <HelmetProvider>
      <ReactHelmet>
        <title>{name || 'ByteBuffetBox'}</title>
        <meta
          name='description'
          content={
            description ||
            'ByteBuffetBox, your perfect choice for your restaurant online menu'
          }
        />
        <meta property='og:title' content={name || 'ByteBuffetBox'} />
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content={`https://bytebuffetbox.com/${username}`}
        />
        <meta
          name='og:description'
          content={
            description ||
            'ByteBuffetBox, your perfect choice for your restaurant online menu'
          }
        />
      </ReactHelmet>
      {children}
    </HelmetProvider>
  )
}

export default Helmet
