import { useState, useEffect } from 'react'
import ProductsData from 'data/Products.json'
import { useParams } from 'react-router-dom'
import { IPriceList } from 'components/Product/PriceList/usePriceList'

export interface ProductsProps {}

interface IProduct {
  id: number
  name: string
  image: string | null
  price: string | null
  priceList: IPriceList[] | null
}

export const useProducts = (props: ProductsProps) => {
  const { subCategoryId } = useParams<{ subCategoryId: string }>()
  const [products, setProducts] = useState<IProduct[]>([])

  useEffect(() => {
    if (subCategoryId) {
      const filteredProducts = ProductsData.filter(
        (product) => product.subCategoryId === +subCategoryId
      )
      setProducts(filteredProducts)
    } else {
      setProducts([])
    }
  }, [subCategoryId])

  return {
    ...props,
    products,
  }
}
