import React from 'react'
import './App.scss'
import Helmet from 'helmet/Helmet'
import Router from 'router/Router'

function App() {
  return (
    <div className='App'>
      <Helmet>
        <Router />
      </Helmet>
    </div>
  )
}

export default App
