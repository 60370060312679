import { useState, useEffect } from 'react'
import subCategoriesData from 'data/SubCategories.json'
import { useParams } from 'react-router-dom'

export interface CategoryProps {}

export const useCategory = (props: CategoryProps) => {
  const { restaurantName, subCategoryId } = useParams<{
    restaurantName: string
    subCategoryId: string
  }>()
  const [subCategoryName, setSubCategoryName] = useState<string>('')

  useEffect(() => {
    if (subCategoryId) {
      const name = subCategoriesData.find(
        (subCategory) => subCategory.id === +subCategoryId
      )?.name

      setSubCategoryName(name || '')
    }
  }, [subCategoriesData])

  return {
    ...props,
    restaurantName,
    subCategoryName,
  }
}
