import CategoriesData from 'data/Categories.json'

export interface CategoriesProps {
  activeCategory: number
  handleCategoryClick: (id: number) => void
}

export const useCategories = (props: CategoriesProps) => {
  return {
    ...props,
    categories: CategoriesData,
  }
}
