import React from 'react'
import { useHome, HomeProps } from './useHome'
import Info from './Info/Info'
import Categories from './Categories/Categories'
import SubCategories from './SubCategories/SubCategories'

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const { activeCategory, handleCategoryClick } = useHome(props)

  return (
    <main>
      <Info />
      <Categories
        activeCategory={activeCategory}
        handleCategoryClick={handleCategoryClick}
      />
      <SubCategories activeCategory={activeCategory} />
    </main>
  )
}

export default Home
