import { useState } from 'react'

export interface HomeProps {}

export const useHome = (props: HomeProps) => {
  const [activeCategory, setActiveCategory] = useState<number>(1)

  const handleCategoryClick = (id: number) => {
    setActiveCategory(id)
  }

  return {
    ...props,
    activeCategory,
    handleCategoryClick,
  }
}
