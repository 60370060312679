import React from 'react'
import { useHeader, HeaderProps } from './useHeader'
import bannerImg from 'img/banner.jpg'
import logoImg from 'img/logo.webp'
import styles from './Header.module.scss'

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { profileImage, coverImage } = useHeader(props)

  return (
    <header
      className={styles.header}
      style={{ backgroundImage: `url(${coverImage || bannerImg})` }}
    >
      <img
        src={profileImage || logoImg}
        className={styles.logo}
        alt='logo'
        width={60}
        height={60}
      />
    </header>
  )
}

export default Header
