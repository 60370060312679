import { useState, useEffect } from 'react'
import SubCategoriesData from 'data/SubCategories.json'

export interface SubCategoriesProps {
  activeCategory: number
}

interface ISubCategory {
  id: number
  name: string
  image: string | null
  categoryId: number
}

export const useSubCategories = (props: SubCategoriesProps) => {
  const { activeCategory } = props
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([])

  useEffect(() => {
    if (activeCategory) {
      const filteredSubCategories = SubCategoriesData.filter(
        (subCategory) => subCategory.categoryId === +activeCategory
      )
      setSubCategories(filteredSubCategories)
    } else {
      setSubCategories([])
    }
  }, [activeCategory])

  return {
    ...props,
    subCategories,
  }
}
