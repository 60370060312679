import React from 'react'
import { useFooter, FooterProps } from './useFooter'
import styles from './Footer.module.scss'

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const {} = useFooter(props)

  return (
    <footer className={styles.footer}>
      <a href='#' className={styles.link} target='_blank'>
        bytebuffetbox.com
      </a>
    </footer>
  )
}

export default Footer
