export interface PriceListProps {
  priceList: IPriceList[]
}

export interface IPriceList {
  label: string
  price: string
}

export const usePriceList = (props: PriceListProps) => {
  return {
    ...props,
  }
}
