import React from 'react'
import { useProducts, ProductsProps } from './useProducts'
import Product from 'components/Product/Product'
import productImage from 'img/product.jpg'

const Products: React.FC<ProductsProps> = (props: ProductsProps) => {
  const { products } = useProducts(props)

  return (
    <div className='container-fluid py-3'>
      <div className='row'>
        {products.length ? (
          products.map((product) => (
            <div
              key={`product-${product.id}`}
              className='col-12 col-md-6 col-lg-4 col-xl-3 mb-4'
            >
              <Product
                id={product.id}
                name={product.name}
                price={product.price}
                priceList={product.priceList}
                image={product.image || productImage}
              />
            </div>
          ))
        ) : (
          <div>Nothing Found Here!</div>
        )}
      </div>
    </div>
  )
}

export default Products
