import InfoData from 'data/Info.json'

export interface HelmetProps {
  children: React.ReactNode
}

export const useHelmet = (props: HelmetProps) => {
  return {
    ...props,
    ...InfoData,
  }
}
