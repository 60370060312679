import InfoData from 'data/Info.json'

export interface InfoProps {}

export const useInfo = (props: InfoProps) => {
  return {
    ...props,
    ...InfoData,
  }
}
