import { IPriceList } from './PriceList/usePriceList'

export interface ProductProps {
  id: number
  name: string
  image: string
  price: string | null
  priceList: IPriceList[] | null
}

export const useProduct = (props: ProductProps) => {
  return {
    ...props,
  }
}
