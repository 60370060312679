import React from 'react'
import { useProduct, ProductProps } from './useProduct'
import PriceList from './PriceList/PriceList'
import styles from './Product.module.scss'

const Product: React.FC<ProductProps> = (props: ProductProps) => {
  const { name, price, priceList, image } = useProduct(props)

  return (
    <div className={styles.product}>
      <img
        src={image}
        alt='product background'
        className={styles.image}
        loading='lazy'
      />
      <div className='d-flex justify-content-between'>
        <div className={styles.product_name}>{name}</div>
        {price ? (
          <div className={styles.product_price}>{price}</div>
        ) : (
          priceList && priceList.length && <PriceList priceList={priceList} />
        )}
      </div>
    </div>
  )
}

export default Product
