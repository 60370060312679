import React from 'react'
import { useCategory, CategoryProps } from './useCategory'
import { Link } from 'react-router-dom'
import Products from './Products/Products'
import styles from './Category.module.scss'

const Category: React.FC<CategoryProps> = (props: CategoryProps) => {
  const { restaurantName, subCategoryName } = useCategory(props)

  return (
    <main>
      <div className={styles.head_container}>
        <h1 className={styles.title}>
          <Link to={`/${restaurantName}`} className={styles.link}>
            <i className='bi bi-arrow-left-circle me-2'></i>
          </Link>
          {subCategoryName}
        </h1>
      </div>
      <Products />
    </main>
  )
}

export default Category
