import React from 'react'
import { useSubCategories, SubCategoriesProps } from './useSubCategories'
import { useParams } from 'react-router-dom'
import styles from './SubCategories.module.scss'
import SubCategory from 'components/SubCategory/SubCategory'
import subCategoryImage from 'img/subcategory.jpeg'

const SubCategories: React.FC<SubCategoriesProps> = (
  props: SubCategoriesProps
) => {
  const { subCategories } = useSubCategories(props)
  const { restaurantName } = useParams<{ restaurantName: string }>()

  return (
    <div className={styles.sub_categories}>
      <div className='container-fluid'>
        <div className='row'>
          {subCategories.length &&
            subCategories.map((subCategory) => (
              <div
                key={`subCategory-${subCategory.id}`}
                className='col-12 col-md-6 col-lg-4 col-xl-3 mb-4'
              >
                <SubCategory
                  name={subCategory.name}
                  image={subCategory.image || subCategoryImage}
                  link={`/${restaurantName}/Category/${subCategory.id}`}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default SubCategories
